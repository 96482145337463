import { useState, useMemo } from 'react';

export type SortDirection = 'asc' | 'desc' | null;

export interface SortState {
  column: string | null;
  direction: SortDirection;
}

export function useSortable<T>(initialData: T[], initialSortState?: SortState) {
  const [sortState, setSortState] = useState<SortState>(
    initialSortState || {
      column: null,
      direction: null
    }
  );

  const handleSort = (column: string) => {
    setSortState(prevState => ({
      column,
      direction: prevState.column === column && prevState.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const sortedData = useMemo(() => {
    if (!sortState.column || !sortState.direction) {
      return initialData;
    }

    return [...initialData].sort((a, b) => {
      const aValue = a[sortState.column as keyof T] ?? '';
      const bValue = b[sortState.column as keyof T] ?? '';

      if (aValue === bValue) return 0;

      const comparison = aValue < bValue ? -1 : 1;
      return sortState.direction === 'asc' ? comparison : -comparison;
    });
  }, [initialData, sortState.column, sortState.direction]);

  const resetSort = () => { setSortState({ column: null, direction: null }) };

  return { sortState, sortedData, handleSort, resetSort };
}