import React from 'react';
import { SortState } from './useSortable';
import './sorting.css';

interface SortableHeaderProps {
    column: string;
    displayName?: string;
    sortState: SortState;
    onSort: (column: string) => void;
}

export const SortableHeader: React.FC<SortableHeaderProps> = ({ column, displayName, sortState, onSort }) => {
    return (
        <th className="sort" onClick={() => onSort(column)}>
            {displayName}
            <span>
                <i className={`fa fa-sort-asc${sortState.column === column && sortState.direction === 'asc' ? ' selected' : ''}`}></i>
                <i className={`fa fa-sort-desc${sortState.column === column && sortState.direction === 'desc' ? ' selected' : ''}`}></i>
            </span>
        </th>
    );
};